@import "src/assets/breakpoints";
@import "src/assets/core/variables";

.text-success {
  color: $green;
}

.text-danger {
  color: $red;
}

.text-warning {
  color: $yellow;
}

.state-width {
  width: 110px;
}

.DisableTableRow .MuiTableCell-root {
  color: $text-muted;
}

.exp-img-small {
  height: 20px;
  width: 20px;
}

.exp-img-medium {
  height: 32px;
  width: 32px;
}

.exp-img-large {
  height: 48px;
  width: 48px;
}

.exp-img-none-small,
.exp-img-none-medium,
.exp-img-none-large {
  color: white;
  background-color: blue;
  border-radius: 50%;
  text-align: center;
}

.exp-img-none-small {
  height: 20px;
  width: 20px;
  line-height: 21px;
  font-size: 10px;
}

.exp-img-none-medium {
  height: 32px;
  width: 32px;
  line-height: 33px;
  font-size: 16px;
}

.exp-img-none-large {
  height: 48px;
  width: 48px;
  line-height: 49px;
  font-size: 22px;
}


@mixin common-pill($height) {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  height: $height;
  flex-shrink: 0;

  width: fit-content;
  padding: 0 10px;
}

.status-pill {
  @include common-pill(19px);

  svg {
    font-size: .8em;
  }
}

.status-pill-list-header {
  @include common-pill(34px);
  background-color: $status-pill-active-bg;
  color: $status-pill-active-text;
  padding: 5px;
}

.status-pill-detail-header {
  @include common-pill(28px);
}

.status-pill-regular,
.status-pill-success {
  background-color: $status-pill-active-bg;
  color: $status-pill-active-text;
}

.status-pill-danger {
  background-color: $status-pill-inactive-bg;
  color: $status-pill-inactive-text;
}

.status-pill-expired {
  background-color: $status-pill-expired-bg;
  color: $status-pill-expired-text;
}

.status-pill-future {
  background-color: $status-pill-future-bg;
  color: $status-pill-future-text;
}

@mixin status-pill-exp-note {
  @include common-pill(21px);
  justify-content: space-between;
  padding: 0 8px;

  .MuiTypography-root {
    margin-left: 8px;
    font-size: 10px;
    font-weight: 600;
  }
}

.status-pill-exp-note-to-check {
  @include status-pill-exp-note();
  background-color: #FFA7001A;
  color: #FFA700;
}

.status-pill-exp-note-checked {
  @include status-pill-exp-note();
  background-color: #D4F6E4;
  color: #2DD37A;
}

@mixin counter-rounded($size: 26px) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $size;
  height: $size;
  border-radius: 50%;
  color: $white;
}

.counter-primary {
  @include counter-rounded;
  background-color: $green-primary;
}

.counter-active {
  @include counter-rounded;
  background-color: $status-pill-active-text;
}

.edition-pill {
  @include common-pill(16px);
  background-color: $green-primary;
  color: $white;
  padding: 4px 8px;
}

.pro-feature-pill {
  @include common-pill(17px);
  width: 32px;
  background-color: #8414D5;
  color: $white;
}

.dashboard-pill {
  @include common-pill(17px);
  padding: 10px 8px;
  background-color: #4472C4;
  color: $white;
}

@include media-breakpoint-down(md) {
  .dashboard-pill .MuiTypography-root {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70px;
  }
}

.tab-bar-selector {
  height: 52px;
  background-color: #CCD7E35A;
  border-radius: 8px;
  align-self: flex-start;
  display: inline-flex;
  align-items: center;
  padding: 0 4px;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;

  .MuiTypography-root {
    user-select: none;
  }

  & > div {
    color: #7991AA;
    padding: 0 15px;
    height: 42px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 5px;

    & .tab-counter {
      @include counter-rounded(24px);
      background-color: #7991AA;
      color: $white;
      flex-shrink: 0;
    }

    &.tab-selected {
      background-color: $green-primary;
      color: $white;
      border-radius: 8px;

      .tab-counter {
        background-color: $white;
        color: $green-primary;
      }
    }

    &.tab-error {
      background-color: #f6f8fb;
      border-radius: 8px;
    }

    &.tab-not-selectable {
      cursor: default;
    }
  }
}

.tab-bar-selector.report {
  background-color: white;
}

@mixin ellipsis-multiline($maxWidth, $height, $lines: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: $maxWidth;
  height: $height;
}

.filter-close-button {
  position: absolute;
  top: 2px;
  right: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(5, 32, 59, 0.153);
  color: #7991AA;

  svg {
    width: 0.6em;
    cursor: pointer;
  }
}

.help-container-wrapper {
  overflow: hidden;
  position: relative;

  .help-container-placement {
    position: absolute;
  }

  .help-container {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, .2);
    border: 2px solid $black;
    background-color: $white;
    z-index: 4000;
    overflow: hidden;
  }
}

#helpHighlight {
  position: absolute;
  z-index: 1210;
  border: 4px solid yellow;
  display: none;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulseHighlight 1s infinite;
}

@keyframes pulseHighlight {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

//.helpAccordion.MuiAccordionSummary-root {
//  justify-content: left;
//  & > .MuiAccordionSummary-content{
//    flex-grow: 1;
//    flex-direction: row;
//  }
//}

.MuiAutocomplete-tag.MuiChip-filled {
  background-color: #e7f9fb;

  .MuiSvgIcon-root {
    color: #bceff3;
    width: 16px;
    height: 16px;
  }
}

.MuiAccordionSummary-content {
  flex-grow: 1;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.stepButton.MuiButtonBase-root {
  color: #14c6d5;
}

.MuiSvgIcon-root.MuiStepIcon-root.Mui-active {
  color: #14c6d5;
}

.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
  color: #14c6d5;
}

//.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
//  color: #14c6d5;
//
//}


.ztsToggle.MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
  color: #ffffff;
  background-color: #14c6d5;
}

.ztsToggle.MuiButtonBase-root.MuiToggleButton-root.Mui-selected:hover {
  background-color: #14c6d5;
}

.ztsToggle.MuiButtonBase-root.MuiToggleButton-root:hover {
  background-color: transparent;
}

.google-maps-icon {
  display: flex;
  height: inherit;
  width: inherit;
}

.license-popup > div:nth-child(3) > .MuiPaper-root {
  border-radius: 40px;
}

.ztsAccordion {
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
}

.multiSelectionsAutocomplete {
  width: 100%;
  border: none;
  padding-left: 8px;

  &:focus-visible {
    outline: none;
  }
}

.multiSelectionsAutocompleteOption {
  padding: 5px 12px;
  display: flex;

  & svg {
    color: transparent;
  }

  &[aria-selected='true'] {
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  &:hover {
    background-color: #e6f7ff;
  }
}

.manager-wrapper {
  color: white;
  position: absolute;
  right: 170px;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.env-wrapper {
  background-color: indigo;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.env-wrapper-login {
  color: white;
  position: absolute;
  font-size: 30px;
  display: flex;
  top: 60px;
  left: 0;
  right: 0;
  justify-content: center;
}

.admin-wrapper {
  background-color: red;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.searchBarTextField.MuiFormControl-root {
  .MuiOutlinedInput-root {
    height: 50px;
  }
}

.creCardTableContainer td.MuiTableCell-root {
  padding: 5px;
}

.importPreviewTableContainer th.MuiTableCell-root {
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid #808080;

  .table-header {
    font-family: monospace !important;
    color: #000000de;
  }
}

.importPreviewTableContainer td.MuiTableCell-root {
  padding: 5px;
  border-bottom-width: 1px;
}

.importPreviewTableContainer tr.MuiTableRow-root:last-child td.MuiTableCell-root {
  border-bottom: 1px solid #808080
}

.importPreviewTableContainer tr.MuiTableRow-hover {

  &:hover {
    td.MuiTableCell-root {
      border-bottom-width: 1px;
      border-color: #808080;
    }
  }
}

.cre-card-mov-per-operation-wrapper, .cre-card-mov-per-upload-wrapper {
  .MuiAccordion-root:first-of-type {
    border-radius: 13px;
  }
}


// I seguenti stili servono nella pagina di gestione dei movimenti carta di credito
// per mostrare un solo raggruppamento aperto alla volta
.creCardTableContainer:has(.MuiAccordion-root.Mui-expanded) {
  overflow: hidden;
}
.cre-card-mov-per-operation-wrapper, .cre-card-mov-per-upload-wrapper, .cre-card-mov-ungrouped-wrapper {
  &:has(.MuiAccordion-root.Mui-expanded) {
    & {
      height: 100%;
    }

    .MuiAccordion-root:not(.Mui-expanded) {
      display: none;
    }

    .MuiAccordion-root {
      margin-top: 0;
    }

    .MuiAccordion-root.Mui-expanded {
      height: 100%;

      .MuiCollapse-entered {
        height: calc(100% - 40px) !important;
      }

      .MuiCollapse-vertical {
        height: 100%;
      }

      .MuiCollapse-wrapper {
        height: 100%;
      }

      .MuiAccordion-region {
        height: 100%;
      }

      .MuiAccordionDetails-root {
        height: 100%;
      }

      .MuiTableContainer-root {
        height: calc(100% - 28px);
      }
    }
  }
}

